import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import kwic_token_icon from '../assets/images/common/tokenimg.png';
import cardano_logo from '../assets/images/common/cardanologo.png';
import Button from '../components/button/index';
import data from '../assets/data/data-nft';
import { useAssets, useWallet } from '@meshsdk/react';
import CryptoJS from 'crypto-js';
import { queryPotentialRewardsWithPending } from '../api/GraphQL/StakeProject/query.ts';
import { mutateCreateStakeTransaction, mutateSubmitStakeTransaction } from '../api/GraphQL/Transaction/Stake/mutation.ts';
import Swal from 'sweetalert2';
import axios from 'axios';

function Stake() {
    const [selectedNFTs, setSelectedNFTs] = useState([]);
    const [selectedPolicyIds, setSelectedPolicyIds] = useState([]);
    const [stakeComponents, setStakeComponents] = useState([]);
    const [isStakingNFTs, setIsStakingNFTs] = useState(false);
    const [isGettingStakedNfts, setIsGettingStakedNfts] = useState(false);
    const [assetStaked, setStakedNFTs] = useState([]);
    const [stakedNFTsArray, setExcludedAssetNames] = useState([]);
    const [stakedNFTsRewardInput, setStakedNFTsRewardInput] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [inmatesFiltered, setInmatesFiltered] = useState([]);

    const [policyIdFilter, setPolicyIdFilter] = useState(null);

    const handleFilterButtonClick = (policyId) => {
        setPolicyIdFilter(policyId);
    };

    let isMaintenance = true;

    let apiKey = '';
    let stakeProjectId = '';
    let nftDomain = '';
    let blockfrostKey = '';

    let maxSelectedNFTs = 12;

    apiKey = process.env.REACT_APP_API_KEY;
    stakeProjectId = process.env.REACT_APP_STAKE_PROJECT_ID;
    blockfrostKey = process.env.REACT_APP_BLOCKFROST_KEY;
    nftDomain = 'kwic';

    const stakeNFTs = async (event) => {
        setIsStakingNFTs(true);
        await stakeNFT(event);
        setIsStakingNFTs(false);
    };

    const handleNFTClick = (assetName, policyId) => {
        const isAlreadySelected = selectedNFTs.includes(assetName);

        if (isAlreadySelected) {
            // Deselect the NFT
            setSelectedNFTs(selectedNFTs.filter((selectedAddress) => selectedAddress !== assetName));
            setSelectedPolicyIds(selectedPolicyIds.filter((selectedAddress) => selectedAddress !== policyId));

            function stringToHex(inputString) {
                let hexString = '';
                for (let i = 0; i < inputString.length; i++) {
                    const hex = inputString.charCodeAt(i).toString(16);
                    hexString += hex.length === 1 ? '0' + hex : hex;
                }
                return hexString;
            }

            const assetNameToHex = stringToHex(assetName);
            
             // Define a mapping of parameters and their replacement values
             const replacements = {
                "000dfffd40": "000de140",
            };

            // Your original string
            const originalHex = assetNameToHex;

            // Create a regex pattern that matches any of the parameters to be replaced
            const regexPattern = new RegExp(Object.keys(replacements).join("|"), "g");

            // Replace the matched parameters with their corresponding values
            const replacedHex = originalHex.replace(regexPattern, (match) => replacements[match]);

            // Remove the corresponding stakeComponent from stakeComponents
            setStakeComponents((prevStakeComponents) =>
                prevStakeComponents.filter((stakeComponent) => stakeComponent.assetName !== replacedHex)
            );
        } else {
            // Select the NFT
            setSelectedNFTs([...selectedNFTs, assetName]);
            if (!selectedPolicyIds.includes(policyId)) {
                setSelectedPolicyIds([...selectedPolicyIds, policyId]);
            }

            function stringToHex(inputString) {
                let hexString = '';
                for (let i = 0; i < inputString.length; i++) {
                    const hex = inputString.charCodeAt(i).toString(16);
                    hexString += hex.length === 1 ? '0' + hex : hex;
                }
                return hexString;
            }

            const assetNameToHex = stringToHex(assetName);
            
             // Define a mapping of parameters and their replacement values
             const replacements = {
                "000dfffd40": "000de140",
            };

            // Your original string
            const originalHex = assetNameToHex;

            // Create a regex pattern that matches any of the parameters to be replaced
            const regexPattern = new RegExp(Object.keys(replacements).join("|"), "g");

            // Replace the matched parameters with their corresponding values
            const replacedHex = originalHex.replace(regexPattern, (match) => replacements[match]);

            // Create a new stakeComponent and add it to stakeComponents
            const newStakeComponent = {
                stakeProjectId: stakeProjectId,
                policyId: policyId,
                assetName: replacedHex,
            };

            setStakeComponents((prevStakeComponents) => [...prevStakeComponents, newStakeComponent]);
        }
    };

    const assets = useAssets();

    const { wallet, connected } = useWallet();

    const stakeNFT = async (event) => {
        event.preventDefault();
        try {
            let timerInterval
            Swal.fire({
                title: 'PROCESSING!',
                html: 'CHECKING TRANSACTION <b></b>',
                timer: 0,
                allowOutsideClick: false,
                allowEscapeKey: false,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading()
                    const b = Swal.getHtmlContainer().querySelector('b')
                    timerInterval = setInterval(() => {
                        b.textContent = Swal.getTimerLeft()
                    }, 100)
                },
                willClose: () => {
                    clearInterval(timerInterval)
                }
            }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {

                }
            });

            const addresses = await wallet.getChangeAddress();
            const paymentAddresses = addresses;

            const createInput = {
                paymentAddress: paymentAddresses,
                stakeComponents: stakeComponents,
                unstakeComponents: [],
                addStakeTokenComponents: [],
            };

            //Create the Submission Object
            const createTransaction = await mutateCreateStakeTransaction(createInput);
            const submitSuccesses = [];
            for (let i = 0; i < createTransaction.successTransactions.length; i++) {
                const transactionId = createTransaction.successTransactions[i].transactionId;
                const hexTransaction = createTransaction.successTransactions[i].hexTransaction;

                // Sign the hex transaction
                const signedTx = await wallet.signTx(hexTransaction, true);

                const submitSuccess = {
                    transactionId: transactionId,
                    hexTransaction: signedTx,
                };
                submitSuccesses.push(submitSuccess);
            }

            //Submit the transaction
            const submitInput = {
                paymentAddress: paymentAddresses,
                successTransactions: submitSuccesses,
            };

            const submitTransaction = await mutateSubmitStakeTransaction(submitInput);

            const transactionIds = submitTransaction?.transactionIds;
            if (!transactionIds || transactionIds.length <= 0 || !!submitTransaction.error) {
                return submitTransaction.error;
            }

            Swal.fire({
                title: 'STAKING SUCCESSFUL!',
                width: 600,
                padding: '3em',
                color: '#FFF',
                background: '#0007FF url(/images/trees.png)',
                allowOutsideClick: false,
                allowEscapeKey: false,
                backdrop: `
                    rgba(0,0,0,0.8)
                    url("/images/nyan-cat.gif")
                    left top
                    no-repeat
                `,
                showConfirmButton: true, // Show the "OK" button
            }).then((result) => {
                if (result.isConfirmed) {
                    /* eslint-disable no-restricted-globals */
                    location.reload(); // Reload the page when the user clicks "OK"
                }
            });
            return Swal;
        } catch (error) {
            const errorMatch = error.message.match(/{"code":(\d+),/);

            if (errorMatch) {
                const errorCode = parseInt(errorMatch[1]);

                if (errorCode === 2) {
                    Swal.fire({
                        title: 'TRANSACTION CANCELLED!',
                        html: 'YOU CANCELLED THE TRANSACTION <b></b>',
                        width: 600,
                        padding: '3em',
                        color: '#FFF',
                        background: '#0007FF url(/images/trees.png)',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        backdrop: `
                          rgba(0,0,0,0.8)
                          url("/images/nyan-cat.gif")
                          left top
                          no-repeat
                        `
                    }).then((result) => {
                        if (result.isConfirmed) {
                            /* eslint-disable no-restricted-globals */
                            location.reload(); // Reload the page when the user clicks "OK"
                        }
                    });
                }
            } else {
                Swal.fire({
                    title: 'AN ERROR OCCURED!',
                    html: 'PLEASE TRY AGAIN LATER <b></b>',
                    width: 600,
                    padding: '3em',
                    color: '#FFF',
                    background: '#0007FF url(/images/trees.png)',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    backdrop: `
                    rgba(0,0,0,0.8)
                    url("/images/nyan-cat.gif")
                    left top
                    no-repeat
                    `
                });
                // Handle the case where the error message format is unexpected
            }
        }
    };


    let targetPolicyIds = [];
    let defaultPolicyId;
    let allowedAssets;
    let collectionType = [];
    let collectionLinks = [];
    let rewardsPerHour = [];
    let filteredAssets = [];

    defaultPolicyId = 'c72d0438330ed1346f4437fcc1c263ea38e933c1124c8d0f2abc6312';
    targetPolicyIds = ["c72d0438330ed1346f4437fcc1c263ea38e933c1124c8d0f2abc6312", "cbb8d9d7415bbcc6f4c8a832d0437e9f6e7fe4605a1cf110ad903d82", "7c15d2a6be43f55000ae6ae0c31b9444a047815a8156df66d363c7a4", "77999d5a1e09f9bdc16393cab713f26345dc0827a9e5134cf0f9da37"];
    allowedAssets = assets && Array.isArray(assets) ? assets.filter((asset) => targetPolicyIds.includes(asset.policyId)) : [];
    collectionType = {
        "c72d0438330ed1346f4437fcc1c263ea38e933c1124c8d0f2abc6312": "KWIC OG",
        "cbb8d9d7415bbcc6f4c8a832d0437e9f6e7fe4605a1cf110ad903d82": "KWIC 1 OF 1",
        "7c15d2a6be43f55000ae6ae0c31b9444a047815a8156df66d363c7a4": "KWIC ORDINAL REWARDS",
        "77999d5a1e09f9bdc16393cab713f26345dc0827a9e5134cf0f9da37": "MULGAKONGZ",
        //"4251471946861a02c7d007cb2b9ba9719674f53ac846e0a27393c84b": "INMATES CELLS",
    };
    rewardsPerHour = {
        "c72d0438330ed1346f4437fcc1c263ea38e933c1124c8d0f2abc6312": "30 $KWIC/DAY",
        "cbb8d9d7415bbcc6f4c8a832d0437e9f6e7fe4605a1cf110ad903d82": "24 $KWIC/DAY",
        "7c15d2a6be43f55000ae6ae0c31b9444a047815a8156df66d363c7a4": "24 $KWIC/DAY",
        "77999d5a1e09f9bdc16393cab713f26345dc0827a9e5134cf0f9da37": "2.4 $KWIC/DAY",
        //"4251471946861a02c7d007cb2b9ba9719674f53ac846e0a27393c84b": "2.4 $KWIC/DAY",
    };
    collectionLinks = {
        "c72d0438330ed1346f4437fcc1c263ea38e933c1124c8d0f2abc6312": "https://www.jpg.store/collection/kwickeyboardwarriorsinternetcafe",
        "cbb8d9d7415bbcc6f4c8a832d0437e9f6e7fe4605a1cf110ad903d82": "https://www.jpg.store/collection/kwicart1of1",
        "7c15d2a6be43f55000ae6ae0c31b9444a047815a8156df66d363c7a4": "https://www.jpg.store/collection/kwicordinalrewards",
        "77999d5a1e09f9bdc16393cab713f26345dc0827a9e5134cf0f9da37": "https://www.jpg.store/collection/mulgakongz",
        //"4251471946861a02c7d007cb2b9ba9719674f53ac846e0a27393c84b": "https://www.jpg.store/collection/inmatescells",
    };
    
    allowedAssets.forEach((item) => {
        function stringToHex(inputString) {
            let hexString = '';
            for (let i = 0; i < inputString.length; i++) {
                const hex = inputString.charCodeAt(i).toString(16);
                hexString += hex.length === 1 ? '0' + hex : hex;
            }
            return hexString;
        }

        const assetNameToHex = stringToHex(item.assetName);

        // Define a mapping of parameters and their replacement values
        const replacements = {
            "000dfffd40": "000de140",
            "�@": "",
            "á@": "",
        };

        // Your original string
        const originalHex = assetNameToHex;

        // Create a regex pattern that matches any of the parameters to be replaced
        const regexPattern = new RegExp(Object.keys(replacements).join("|"), "g");

        // Replace the matched parameters with their corresponding values
        const replacedHex = originalHex.replace(regexPattern, (match) => replacements[match]);
        // Push the staked NFT to the array
        stakedNFTsRewardInput.push({ policyId: item.policyId, assetName: replacedHex });
    });

    /**

    // Function to fetch asset metadata
    const fetchAssetMetadata = async (unit) => {
        try {
            const response = await axios.get(`https://cardano-mainnet.blockfrost.io/api/v0/assets/${unit}`, {
                headers: { project_id: blockfrostKey },
            });
            return response.data;
        } catch (error) {
            console.error(`Failed to fetch metadata for unit: ${unit}`, error);
            return null;
        }
    };

    // Main function to filter assets with specific metadata
    const filterAssetsByPet = async (assets) => {
        const result = [];

        for (const asset of assets) {
            const metadata = await fetchAssetMetadata(asset.unit);

            if (
                metadata &&
                metadata.onchain_metadata &&
                metadata.onchain_metadata.Partner === 'Kwic'
            ) {
                result.push(asset); // Add the matching asset to the result array
            }
        }

        return result;
    };
    **/

    filteredAssets = allowedAssets.filter((asset) => !stakedNFTsArray.includes(asset.assetName));

    const onLoadDelay = async (event) => {
        // Add a delay of 1 second (adjust as needed)
        await new Promise(resolve => setTimeout(resolve, 8000));
        // Call the function you want to execute after the delay
        getStakedNFTs();
        handleFilterButtonClick(defaultPolicyId);
    };

    const getStakedNFTs = async (event) => {
        setIsGettingStakedNfts(true);
        setIsLoading(true);
        await getStakedNFTsFunction(event);
        setIsGettingStakedNfts(false);
        setIsLoading(false);
    };

    const getStakedNFTsFunction = async (event) => {
        try {

            const GetPotentialRewardsInput = {
                stakeProjectId: stakeProjectId,
                nftStakeRewardWithPendingInputs: stakedNFTsRewardInput,
            };

            const result = await queryPotentialRewardsWithPending(GetPotentialRewardsInput);

            result.forEach((item) => {
                function hexToString(hex) {
                    let string = '';
                    for (let i = 0; i < hex.length; i += 2) {
                        string += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
                    }
                    return string;
                }

                const stringAssetNames = hexToString(item.assetName);

                // Define a mapping of parameters and their replacement values
                const replacements = {
                    "á@": "�@",
                };

                // Your original string
                const originalString = stringAssetNames;

                // Create a regex pattern that matches any of the parameters to be replaced
                const regexPattern = new RegExp(Object.keys(replacements).join("|"), "g");

                // Replace the matched parameters with their corresponding values
                const replacedString = originalString.replace(regexPattern, (match) => replacements[match]);

                // Push the staked NFT to the array
                stakedNFTsArray.push(replacedString);
            });
        } catch (error) {

        }
    };

    /**
    useEffect(() => {
        filterAssetsByPet(filteredAssets).then((filteredByPet) => {
            setInmatesFiltered(filteredByPet); // Correctly update the state
        });
    }, [filteredAssets]);
    **/

    // Effect to listen for changes in assets
    useEffect(() => {
        onLoadDelay();
    }, []);

    if(isMaintenance){
        return (
            <div className='inner-page'>
                <section className="tf-section tf-wallet" data-aos-delay="200" data-aos="fade-up" data-aos-duration="300">
                    <div className="container">
                        <div className="row">   
                            <div className="col-lg-12 center">
                                <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
                                <h1>DASHBOARD UNDER MAINTENANCE</h1>
                                <h4>We’re currently performing some updates to improve your experience. Our dashboard will be back online shortly <br></br><br></br>
    Thank you for your patience!</h4>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }else{
        return (
            <div className='inner-page'>
                <section className="tf-section project_2">
                    {connected ? (
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="tf-title left mb-400 mt-40">
                                        <div className="row">
                                            <div className="col-lg-10">
                                                <h3 className="title">
                                                    SELECT NFT'S TO STAKE
                                                </h3>
                                                <h4 className="title">
                                                    SELECTED NFT'S: <span className="selected_num">{selectedNFTs.length}</span>
                                                </h4>
                                            </div>
                                            <div className="col-lg-2 page-button">
                                                {selectedNFTs.length === 0 ? (
                                                    <Button title="STAKE" addclass='stake_btn disabled' />
                                                ) : (
                                                    <Button title="STAKE" onClick={stakeNFTs} addclass='stake_btn' />
                                                )}
                                            </div>
                                            <div className="col-lg-12">
                                                {targetPolicyIds.map((policyId) => (
                                                    <Button
                                                        key={policyId}
                                                        title={collectionType[policyId]}
                                                        onClick={() => handleFilterButtonClick(policyId)}
                                                        addclass={`stake_btn filter ${policyIdFilter === policyId ? 'disabled' : ''}`}
                                                        disabled={policyIdFilter === policyId}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                {isLoading ? (
                                    <div>
                                        <div className="col-lg-12 mb-100">
                                            <div className="no-assets">
                                                <span className="loader"></span><br></br>
                                                <h4>CHECKING WALLET</h4>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div className="col-lg-12">
                                            <div className="project_wrapper_2">
                                                {filteredAssets
                                                    .filter((asset) => {
                                                        // If the policyIdFilter matches the special policy ID, only show inmatesFiltered assets
                                                        if (policyIdFilter === "4251471946861a02c7d007cb2b9ba9719674f53ac846e0a27393c84b") {
                                                            // Show only the assets in inmatesFiltered for this policyId
                                                            return inmatesFiltered.some((filteredAsset) => filteredAsset.unit === asset.unit);
                                                        }
    
                                                        // Otherwise, show all assets that match the policyIdFilter
                                                        return policyIdFilter === null || asset.policyId === policyIdFilter;
                                                    })
                                                    .length === 0 ? (
                                                    <div className="no-available-asset mb-100">
                                                        <h3 className="text-center no-nft">NO AVAILABLE NFT'S</h3>
                                                        <Button title={`BUY ${collectionType[policyIdFilter]}`} onClick={() => window.location.href = collectionLinks[policyIdFilter]} addclass='stake_btn buy_btn' />
                                                    </div>
                                                ) : (
                                                    filteredAssets
                                                        .filter((asset) => {
                                                            if (policyIdFilter === "4251471946861a02c7d007cb2b9ba9719674f53ac846e0a27393c84b") {
                                                                // Only show inmatesFiltered assets for the special policy ID
                                                                return inmatesFiltered.some((filteredAsset) => filteredAsset.unit === asset.unit);
                                                            }
    
                                                            // Otherwise, show all assets matching policyIdFilter
                                                            return policyIdFilter === null || asset.policyId === policyIdFilter;
                                                        })
                                                        .map((asset) => {
                                                            const collectionResult = collectionType[asset.policyId];
                                                            const rewardsResult = rewardsPerHour[asset.policyId];
    
                                                            const domain = nftDomain;
                                                            const key = CryptoJS.enc.Base64.parse(apiKey);
                                                            const token = asset.fingerprint;
    
                                                            function buildUrl(domain, token, uri, params) {
                                                                const searchParams = new URLSearchParams(params);
                                                                return `https://${token}.${domain}.nftcdn.io${uri}?${searchParams.toString()}`;
                                                            }
    
                                                            function nftcdnUrl(domain, key, token, uri, params = {}) {
                                                                params.tk = "";
                                                                let url = buildUrl(domain, token, uri, params);
                                                                params.tk = CryptoJS.enc.Base64url.stringify(CryptoJS.HmacSHA256(url, key));
                                                                return buildUrl(domain, token, uri, params);
                                                            }
    
                                                            const originalImageUrl = nftcdnUrl(domain, key, token, "/image", { size: 512 });
    
                                                            // Define a mapping of parameters and their replacement values
                                                            const replacements = {
                                                                "KWIC": "#",
                                                                "Organised": "#",
                                                                "White Collar": "#",
                                                                "Psychopaths": "#",
                                                                "Habitual": "#",
                                                                "OrdinalRewards": "",
                                                                "MulgaKong": "#",
                                                                "�@": "",
                                                            };
    
                                                            // Your original string
                                                            const originalString = asset.assetName;
    
                                                            // Create a regex pattern that matches any of the parameters to be replaced
                                                            const regexPattern = new RegExp(Object.keys(replacements).join("|"), "g");
    
                                                            // Replace the matched parameters with their corresponding values
                                                            const replacedString = originalString.replace(regexPattern, (match) => replacements[match]);
    
                                                            //{originalImageUrl}
    
                                                            return (
                                                                <div className="project-box-style7" key={asset.unit}>
                                                                    {selectedNFTs.length === maxSelectedNFTs && !selectedNFTs.includes(asset.assetName) ? (
                                                                        <>
                                                                            <div className="nft_disabled"></div>
                                                                            <div className="nft_selection_full" >
                                                                                <div className="image">
                                                                                    <img src={originalImageUrl} alt="" />
                                                                                </div>
                                                                                <div className="content">
                                                                                    <h5 className="heading">{replacedString}</h5>
                                                                                    <ul>
                                                                                        <li>
                                                                                            <p>{rewardsResult}</p>
                                                                                        </li>
                                                                                        <li>
                                                                                            <p>{collectionResult}</p>
                                                                                        </li>
                                                                                    </ul>
                                                                                    <div className="content-progress-box style2">
                                                                                        <div className="progress-bar" data-percentage="100%">
                                                                                            <div className="progress-title-holder">
                                                                                                <span className="progress-title">
                                                                                                    <span className="progress_number">
                                                                                                        <span className="progress-number-mark">
    
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <Link key={asset.unit} alt={asset.assetName} className={`nft_selection ${selectedNFTs.includes(asset.assetName) ? 'selected' : ''}`} onClick={() => handleNFTClick(asset.assetName, asset.policyId)} >
                                                                            <div className="image">
                                                                                <img src={originalImageUrl} alt="" />
                                                                            </div>
                                                                            <div className="content">
                                                                                <h5 className="heading">{replacedString}</h5>
                                                                                <ul>
                                                                                    <li>
                                                                                        <p>{rewardsResult}</p>
                                                                                    </li>
                                                                                    <li>
                                                                                        <p>{collectionResult}</p>
                                                                                    </li>
                                                                                </ul>
                                                                                <div className="content-progress-box style2">
                                                                                    <div className="progress-bar" data-percentage="100%">
                                                                                        <div className="progress-title-holder">
                                                                                            <span className="progress-title">
                                                                                                <span className="progress_number">
                                                                                                    <span className="progress-number-mark">
    
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    )}
                                                                </div>
                                                            );
                                                        })
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="container mb-100">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="project_wrapper_2">
                                        <div className="no-wallet-connected"> <h2>CONNECT TO A WALLET</h2></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </section>
            </div>
        );
    }

}

export default Stake;