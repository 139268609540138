import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import kwic_token_icon from '../assets/images/common/tokenimg.png';
import Button from '../components/button/index';
import { useAssets } from '@meshsdk/react';
import { useWallet } from '@meshsdk/react';
import CryptoJS from 'crypto-js';
import { queryPotentialRewards, queryStakedNftMainPairs, queryDailyRewards, queryPotentialRewardsWithPending } from '../api/GraphQL/StakeProject/query.ts';
import { mutateCreateStakeTransaction, mutateSubmitStakeTransaction } from '../api/GraphQL/Transaction/Stake/mutation.ts';
import Swal from 'sweetalert2';

function Stabilise() {
    const [selectedNFTs, setSelectedNFTs] = useState([]);
    const [selectedPairNFTs, setSelectedPairNFTs] = useState([]);
    const [selectedPolicyIds, setSelectedPolicyIds] = useState([]);
    const [unstakeComponents, setUnStakeComponents] = useState([]);
    const [isUnstakingNFTs, setIsUnstakingNFTs] = useState(false);
    const [potentialRewards, setPotentialRewards] = useState([]);
    const [dailyRewards, setDailyRewards] = useState([]);
    const [isGettingStakedNfts, setIsGettingStakedNfts] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [stakedNFTsArray, setExcludedAssetNames] = useState([]);
    const [stakedNFTsRewardInput, setStakedNFTsRewardInput] = useState([]);
    const [playableAvatarPairs, setPlayableAvatarPairs] = useState([]);
    const [stakedNFTPairInputArray, setStakedNFTPairInputArray] = useState([]);
    const [fetchedPairsArray, setFetchedPairsArray] = useState([]);
    const [pairSelected, setPairSelected] = useState(false);


    const [selectAll, setSelectAll] = useState(false);

    const [totalAccumulatedKWIC, setTotalAccumulatedKWIC] = useState(0);

    const [policyIdFilter, setPolicyIdFilter] = useState(null);

    const handleFilterButtonClick = (policyId) => {
        setPolicyIdFilter(policyId);
    };

    let isMaintenance = true;

    let daysReqToStabilise = 10;
    let maxSelectedNFTs = 12;

    let apiKey = '';
    let stakeProjectId = '';
    let nftDomain = '';

    let targetPolicyIds = [];
    let defaultPolicyId;
    let excludedPolicyId;
    let allowedAssets;
    let collectionType = [];
    let collectionLinks = [];
    let filteredAssets = [];
    let filteredAssetsPairs = [];

    apiKey = process.env.REACT_APP_API_KEY;
    stakeProjectId = process.env.REACT_APP_STAKE_PROJECT_ID;
    nftDomain = 'kwic';

    const unstakeNFTs = async (event) => {
        setIsUnstakingNFTs(true);
        await unstakeNFT(event);
        setIsUnstakingNFTs(false);
    };

    const assets = useAssets();

    defaultPolicyId = 'c72d0438330ed1346f4437fcc1c263ea38e933c1124c8d0f2abc6312';
    excludedPolicyId = 'b98ae818ee0693e61a8e51db1358240eaa441dba60a8c3766e3f5115';
    targetPolicyIds = ["c72d0438330ed1346f4437fcc1c263ea38e933c1124c8d0f2abc6312", "cbb8d9d7415bbcc6f4c8a832d0437e9f6e7fe4605a1cf110ad903d82", "7c15d2a6be43f55000ae6ae0c31b9444a047815a8156df66d363c7a4", "77999d5a1e09f9bdc16393cab713f26345dc0827a9e5134cf0f9da37", "b98ae818ee0693e61a8e51db1358240eaa441dba60a8c3766e3f5115"];
    allowedAssets = assets && Array.isArray(assets) ? assets.filter((asset) => targetPolicyIds.includes(asset.policyId)) : [];
    collectionType = {
        "c72d0438330ed1346f4437fcc1c263ea38e933c1124c8d0f2abc6312": "KWIC OG",
        "cbb8d9d7415bbcc6f4c8a832d0437e9f6e7fe4605a1cf110ad903d82": "KWIC 1 OF 1",
        "7c15d2a6be43f55000ae6ae0c31b9444a047815a8156df66d363c7a4": "KWIC ORDINAL REWARDS",
        "77999d5a1e09f9bdc16393cab713f26345dc0827a9e5134cf0f9da37": "MULGAKONGZ",
    };
    
    collectionLinks = {
        "c72d0438330ed1346f4437fcc1c263ea38e933c1124c8d0f2abc6312": "https://www.jpg.store/collection/kwickeyboardwarriorsinternetcafe",
        "cbb8d9d7415bbcc6f4c8a832d0437e9f6e7fe4605a1cf110ad903d82": "https://www.jpg.store/collection/kwicart1of1",
        "7c15d2a6be43f55000ae6ae0c31b9444a047815a8156df66d363c7a4": "https://www.jpg.store/collection/kwicordinalrewards",
        "77999d5a1e09f9bdc16393cab713f26345dc0827a9e5134cf0f9da37": "https://www.jpg.store/collection/mulgakongz",

    };

    allowedAssets.forEach((item) => {
        function stringToHex(inputString) {
            let hexString = '';
            for (let i = 0; i < inputString.length; i++) {
                const hex = inputString.charCodeAt(i).toString(16);
                hexString += hex.length === 1 ? '0' + hex : hex;
            }
            return hexString;
        }

        const assetNameToHex = stringToHex(item.assetName);

        // Define a mapping of parameters and their replacement values
        const replacements = {
            "000dfffd40": "000de140",
        };

        // Your original string
        const originalHex = assetNameToHex;

        // Create a regex pattern that matches any of the parameters to be replaced
        const regexPattern = new RegExp(Object.keys(replacements).join("|"), "g");

        // Replace the matched parameters with their corresponding values
        const replacedHex = originalHex.replace(regexPattern, (match) => replacements[match]);
        // Push the staked NFT to the array
        stakedNFTsRewardInput.push({ policyId: item.policyId, assetName: replacedHex });

        if (item.policyId === defaultPolicyId) {
            playableAvatarPairs.push({ assetName: replacedHex });
        }
    });

    filteredAssets = allowedAssets.filter((asset) => stakedNFTsArray.includes(asset.assetName));
    filteredAssetsPairs = fetchedPairsArray;

    const onLoadDelay = async (event) => {
        // Add a delay of 1 second (adjust as needed)
        await new Promise(resolve => setTimeout(resolve, 8000));

        // Call the function you want to execute after the delay
        getStakedNFTs();
        handleFilterButtonClick(defaultPolicyId);

    };

    const { wallet, connected } = useWallet();

    const handleNFTClick = (assetName, policyId) => {
        const isAlreadySelected = selectedNFTs.includes(assetName);
        

        if (isAlreadySelected) {
            // Deselect the NFT
            setSelectedNFTs(selectedNFTs.filter((selectedAddress) => selectedAddress !== assetName));
            setSelectedPolicyIds(selectedPolicyIds.filter((selectedAddress) => selectedAddress !== policyId));

            function stringToHex(inputString) {
                let hexString = '';
                for (let i = 0; i < inputString.length; i++) {
                    const hex = inputString.charCodeAt(i).toString(16);
                    hexString += hex.length === 1 ? '0' + hex : hex;
                }
                return hexString;
            }

            const assetNameToHex = stringToHex(assetName);
            

            // Remove the corresponding stakeComponent from stakeComponents
            setUnStakeComponents((prevUnStakeComponents) =>
                prevUnStakeComponents.filter((unstakeComponent) => unstakeComponent.assetName !== assetNameToHex)
            );
            
        } else {
            // Select the NFT
            setSelectedNFTs([...selectedNFTs, assetName]);
            if (!selectedPolicyIds.includes(policyId)) {
                setSelectedPolicyIds([...selectedPolicyIds, policyId]);
            }

            function stringToHex(inputString) {
                let hexString = '';
                for (let i = 0; i < inputString.length; i++) {
                    const hex = inputString.charCodeAt(i).toString(16);
                    hexString += hex.length === 1 ? '0' + hex : hex;
                }
                return hexString;
            }

            const assetNameToHex = stringToHex(assetName);

            // Create a new stakeComponent and add it to stakeComponents
            const newUnStakeComponent = {
                stakeProjectId: stakeProjectId,
                policyId: policyId,
                assetName: assetNameToHex,
            };
            setUnStakeComponents((prevUnStakeComponents) => [...prevUnStakeComponents, newUnStakeComponent]);
            
        }
    };
    
    const handleNFTPairClick = (assetName, policyId) => {
        const isPairAlreadySelected = selectedPairNFTs.includes(assetName);

        if (isPairAlreadySelected) {
            
            setSelectedPairNFTs(selectedPairNFTs.filter((selectedAddress) => selectedAddress !== assetName));
            // Remove the corresponding stakeComponent from stakeComponents
            setUnStakeComponents((prevUnStakeComponents) =>
                prevUnStakeComponents.filter((unstakeComponent) => unstakeComponent.assetName !== assetName)
            );
            setPairSelected(false);
        } else {
            setPairSelected(true);
            setSelectedPairNFTs([...selectedPairNFTs, assetName]);
            // Create a new stakeComponent and add it to stakeComponents
            const newUnStakeComponent = {
                stakeProjectId: stakeProjectId,
                policyId: policyId,
                assetName: assetName,
            };
            setUnStakeComponents((prevUnStakeComponents) => [...prevUnStakeComponents, newUnStakeComponent]);
            
        }
    };
   
    
    /**
    // Function to handle "Select All" button
    const handleSelectAll = (assetName, policyId) => {
        const isAlreadySelected = selectedNFTs.includes(assetName);
        const isAlreadySelectedPolicy = selectedPolicyIds.includes(policyId);

        // Select the NFT
        setSelectedNFTs([...selectedNFTs, assetName]);
        if (!selectedPolicyIds.includes(policyId)) {
            setSelectedPolicyIds([...selectedPolicyIds, policyId]);
        }

        function stringToHex(inputString) {
            let hexString = '';
            for (let i = 0; i < inputString.length; i++) {
                const hex = inputString.charCodeAt(i).toString(16);
                hexString += hex.length === 1 ? '0' + hex : hex;
            }
            return hexString;
        }

        const assetNameToHex = stringToHex(assetName);

        // Create a new stakeComponent and add it to stakeComponents
        const newUnStakeComponent = {
            stakeProjectId: stakeProjectId,
            policyId: policyId,
            assetName: assetNameToHex,
        };

        setUnStakeComponents((prevUnStakeComponents) => [...prevUnStakeComponents, newUnStakeComponent]);
    };

    // Function to handle "Select All" button
    const handleDeSelectAll = (assetName, policyId) => {
        const isAlreadySelected = selectedNFTs.includes(assetName);
        const isAlreadySelectedPolicy = selectedPolicyIds.includes(policyId);

        // Deselect the NFT
        setSelectedNFTs(selectedNFTs.filter((selectedAddress) => selectedAddress !== assetName));
        setSelectedPolicyIds(selectedPolicyIds.filter((selectedAddress) => selectedAddress !== policyId));

        // Remove the corresponding stakeComponent from stakeComponents
        setUnStakeComponents((prevUnStakeComponents) =>
            prevUnStakeComponents.filter((unstakeComponent) => unstakeComponent.assetName !== assetName)
        );
        setSelectAll(!selectAll);
    };
    */

    const unstakeNFT = async (event) => {
        event.preventDefault();
        try {
            let timerInterval
            Swal.fire({
                title: 'PROCESSING!',
                html: 'CHECKING TRANSACTION <b></b>',
                timer: 0,
                allowOutsideClick: false,
                allowEscapeKey: false,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading()
                    const b = Swal.getHtmlContainer().querySelector('b')
                    timerInterval = setInterval(() => {
                        b.textContent = Swal.getTimerLeft()
                    }, 100)
                },
                willClose: () => {
                    clearInterval(timerInterval)
                }
            }).then((result) => {
                
                if (result.dismiss === Swal.DismissReason.timer) {

                }
            });

            const addresses = await wallet.getChangeAddress();
            const paymentAddresses = addresses;

            const createInput = {
                paymentAddress: paymentAddresses,
                stakeComponents: [],
                unstakeComponents: unstakeComponents,
                addStakeTokenComponents: [],
            };

            //Create the Submission Object
            const createTransaction = await mutateCreateStakeTransaction(createInput);
            const submitSuccesses = [];


            console.log(createTransaction);


            if (createTransaction.successTransactions === null) {
                
            } else {
                for (let i = 0; i < createTransaction.successTransactions.length; i++) {
                    const transactionId = createTransaction.successTransactions[i].transactionId;
                    const hexTransaction = createTransaction.successTransactions[i].hexTransaction;

                    // Sign the hex transaction
                    const signedTx = await wallet.signTx(hexTransaction, true);

                    const submitSuccess = {
                        transactionId: transactionId,
                        hexTransaction: signedTx,
                    };
                    submitSuccesses.push(submitSuccess);
                }

                //Submit the transaction
                const submitInput = {
                    paymentAddress: paymentAddresses,
                    successTransactions: submitSuccesses,
                };


                //localStorage.setItem('unstakedAsset', JSON.stringify([...(JSON.parse(localStorage.getItem('unstakedAsset')) || []), unstakedAssetNames]));

                const submitTransaction = await mutateSubmitStakeTransaction(submitInput);
                const transactionIds = submitTransaction?.transactionIds;
                if (!transactionIds || transactionIds.length <= 0 || !!submitTransaction.error) {
                    Swal.fire({
                        title: 'INPUTS EXHAUSTED!',
                        html: 'PLEASE ADD FUNDS, CHECK THE ASSETS IN THIS WALLET OR WAIT FOR ANY PENDING TRANSACTION ON THIS WALLET. <b></b>',
                        width: 600,
                        padding: '3em',
                        color: '#FFF',
                        background: '#0007FF url(/images/trees.png)',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        backdrop: `
                        rgba(0,0,0,0.8)
                        url("/images/nyan-cat.gif")
                        left top
                        no-repeat
                        `
                    });
                } else {

                    Swal.fire({
                        title: 'STABILISE SUCCESSFUL!',
                        width: 600,
                        padding: '3em',
                        color: '#FFF',
                        background: '#0007FF url(/images/trees.png)',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        backdrop: `
                            rgba(0,0,0,0.8)
                            url("/images/nyan-cat.gif")
                            left top
                            no-repeat
                        `,
                        showConfirmButton: true, // Show the "OK" button
                    }).then((result) => {
                        if (result.isConfirmed) {
                            /* eslint-disable no-restricted-globals */
                            location.reload(); // Reload the page when the user clicks "OK"
                        }
                    });
                }
            }

        } catch (error) {

            const errorMatch = error.message.match(/{"code":(\d+),/);

            if (errorMatch) {
                const errorCode = parseInt(errorMatch[1]);

                if (errorCode === 2) {
                    Swal.fire({
                        title: 'TRANSACTION CANCELLED!',
                        html: 'YOU CANCELLED THE TRANSACTION <b></b>',
                        width: 600,
                        padding: '3em',
                        color: '#FFF',
                        background: '#0007FF url(/images/trees.png)',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        backdrop: `
                          rgba(0,0,0,0.8)
                          url("/images/nyan-cat.gif")
                          left top
                          no-repeat
                        `
                    }).then((result) => {
                        if (result.isConfirmed) {
                            /* eslint-disable no-restricted-globals */
                            location.reload(); // Reload the page when the user clicks "OK"
                        }
                    });
                } else {

                    Swal.fire({
                        title: 'AN ERROR OCCURED!',
                        html: 'PLEASE TRY AGAIN LATER <b></b>',
                        width: 600,
                        padding: '3em',
                        color: '#FFF',
                        background: '#0007FF url(/images/trees.png)',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        backdrop: `
                        rgba(0,0,0,0.8)
                        url("/images/nyan-cat.gif")
                        left top
                        no-repeat
                        `
                    });
                }
            } else {
                Swal.fire({
                    title: 'AN ERROR OCCURED!',
                    html: 'PLEASE TRY AGAIN LATER <b></b>',
                    width: 600,
                    padding: '3em',
                    color: '#FFF',
                    background: '#0007FF url(/images/trees.png)',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    backdrop: `
                    rgba(0,0,0,0.8)
                    url("/images/nyan-cat.gif")
                    left top
                    no-repeat
                    `
                });
                // Handle the case where the error message format is unexpected
            }
        }
    };

    const getStakedNFTs = async (event) => {
        setIsGettingStakedNfts(true);
        setIsLoading(true);
        await getStakedNFTsFunction(event);
        await getStakedNFTMainPairsFunction(event);
        await getNFTDailyRewardsFunction(event);
        setIsGettingStakedNfts(false);
        setIsLoading(false);
    };

    const getStakedNFTsFunction = async (event) => {
        try {
            const GetPotentialRewardsInput = {
                stakeProjectId: stakeProjectId,
                nftStakeRewardInputs: stakedNFTsRewardInput,
            };

            const result = await queryPotentialRewards(GetPotentialRewardsInput);

            // Set the potential rewards and make sure it's done before proceeding
            setPotentialRewards(result);

            result.forEach((item) => {
                function hexToString(hex) {
                    let string = '';
                    for (let i = 0; i < hex.length; i += 2) {
                        string += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
                    }
                    return string;
                }

                const stringAssetNames = hexToString(item.assetName);

                stakedNFTPairInputArray.push(item.assetName);

                // Define a mapping of parameters and their replacement values
                const replacements = {
                    "á@": "�@",
                };

                // Your original string
                const originalString = stringAssetNames;

                // Create a regex pattern that matches any of the parameters to be replaced
                const regexPattern = new RegExp(Object.keys(replacements).join("|"), "g");

                // Replace the matched parameters with their corresponding values
                const replacedString = originalString.replace(regexPattern, (match) => replacements[match]);

                // Push the staked NFT to the array
                stakedNFTsArray.push(replacedString);
            });

            const totalRewards = result.reduce((sum, item) => {
                return sum + item.rewards;
            }, 0);


            const formattedTotalRewards = totalRewards.toLocaleString('en-US', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });

            setTotalAccumulatedKWIC(typeof formattedTotalRewards === 'number' && formattedTotalRewards % 1 === 0
                ? formattedTotalRewards.toFixed(0)
                : formattedTotalRewards);



        } catch (error) {

        }
    };

    const getStakedNFTMainPairsFunction = async (event) => {
        try {

            const getStakedNFTMainPairsInput = {
                stakeProjectId: stakeProjectId,
                policyId: defaultPolicyId,
                stakedNFTPairInputs: playableAvatarPairs,
            };

            const result = await queryStakedNftMainPairs(getStakedNFTMainPairsInput);
            setFetchedPairsArray(result);
        } catch (error) {
        }
    };

    const getNFTDailyRewardsFunction = async (event) => {

        try {
            const getPotentialRewardsInput = {
                stakeProjectId: stakeProjectId,
                nftDailyStakeRewardInputs: stakedNFTsRewardInput,
            };

            const result = await queryDailyRewards(getPotentialRewardsInput);
            setDailyRewards(result);
        } catch (error) {

        }
    };

    // Effect to listen for changes in assets
    useEffect(() => {
        onLoadDelay();
    }, []);

    if(isMaintenance){
        return (
            <div className='inner-page'>
                <section className="tf-section tf-wallet" data-aos-delay="200" data-aos="fade-up" data-aos-duration="300">
                    <div className="container">
                        <div className="row">   
                            <div className="col-lg-12 center">
                                <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
                                <h1>DASHBOARD UNDER MAINTENANCE</h1>
                                <h4>We’re currently performing some updates to improve your experience. Our dashboard will be back online shortly <br></br><br></br>
    Thank you for your patience!</h4>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }else{
        return (
            <div className='inner-page'>
                <section className="tf-section project_2">
                    {connected ? (
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="tf-title left mb-400 mt-40">
                                        <div className="row">
                                            <div className="col-lg-10">
                                                <h3 className="title">
                                                    SELECT NFT'S TO STABILISE
                                                </h3>
                                                <h4 className="title">
                                                    SELECTED NFT'S: <span className="selected_num">{selectedNFTs.length}</span>
                                                    <br />ACCUMULATED $KWIC: <span className="selected_num">{totalAccumulatedKWIC}</span>
                                                </h4>
                                            </div>
                                            <div className="col-lg-2 page-button">
                                                {selectedNFTs.length === 0 ? (
                                                    <Button title="STABILISE" addclass="stake_btn disabled" />
                                                ) : (
                                                    <Button title="STABILISE" onClick={unstakeNFTs} addclass="stake_btn" />
                                                )}
                                            </div>
                                            <div className="col-lg-12">
                                                {targetPolicyIds.map((policyId) => (
                                                    // Check if the current policyId is not the excluded one
                                                    policyId !== excludedPolicyId && (
                                                        <Button
                                                            key={policyId}
                                                            title={collectionType[policyId]}
                                                            onClick={() => handleFilterButtonClick(policyId)}
                                                            addclass={`stake_btn filter ${policyIdFilter === policyId ? 'disabled' : ''}`}
                                                            disabled={policyIdFilter === policyId}
                                                        />
                                                    )
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                {isLoading ? (
                                    <div>
                                        <div className="col-lg-12 mb-100">
                                            <div className="no-assets">
                                                <span className="loader"></span><br></br>
                                                <h4>CHECKING WALLET</h4>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="col-lg-12">
                                        <div className="project_wrapper_2">
                                            {filteredAssets
                                                .filter((asset) => policyIdFilter === null || asset.policyId === policyIdFilter)
                                                .length === 0 ? (
                                                <div className="no-available-asset mb-100">
                                                    <h3 className="text-center no-nft">NO AVAILABLE<br></br>{collectionType[policyIdFilter]}</h3>
                                                </div>
                                            ) : (
                                                filteredAssets
                                                    .filter((asset) => policyIdFilter === null || asset.policyId === policyIdFilter)
                                                    .map((asset, index) => {
                                                        const collectionResult = collectionType[asset.policyId];
    
                                                        const domain = nftDomain;
                                                        const key = CryptoJS.enc.Base64.parse(apiKey);
                                                        const token = asset.fingerprint;
    
                                                        function buildUrl(domain, token, uri, params) {
                                                            const searchParams = new URLSearchParams(params);
                                                            return `https://${token}.${domain}.nftcdn.io${uri}?${searchParams.toString()}`;
                                                        }
    
                                                        function nftcdnUrl(domain, key, token, uri, params = {}) {
                                                            params.tk = "";
                                                            let url = buildUrl(domain, token, uri, params);
                                                            params.tk = CryptoJS.enc.Base64url.stringify(CryptoJS.HmacSHA256(url, key));
                                                            return buildUrl(domain, token, uri, params);
                                                        }
    
                                                        const originalImageUrl = nftcdnUrl(domain, key, token, "/image", { size: 512 });
    
    
                                                        // Define a mapping of parameters and their replacement values
                                                        const replacements = {
                                                            "KWIC": "#",
                                                            "OrdinalRewards": "",
                                                            "MulgaKong": "#",
                                                            "�@": "",
                                                        };
    
                                                        // Your original string
                                                        const originalString = asset.assetName;
    
                                                        // Create a regex pattern that matches any of the parameters to be replaced
                                                        const regexPattern = new RegExp(Object.keys(replacements).join("|"), "g");
    
                                                        // Replace the matched parameters with their corresponding values
                                                        const replacedString = originalString.replace(regexPattern, (match) => replacements[match]);
    
                                                        //{originalImageUrl}
    
                                                        function stringToHex(inputString) {
                                                            let hexString = '';
                                                            for (let i = 0; i < inputString.length; i++) {
                                                                const hex = inputString.charCodeAt(i).toString(16);
                                                                hexString += hex.length === 1 ? '0' + hex : hex;
                                                            }
                                                            return hexString;
                                                        }
    
                                                        const assetNameToHex = stringToHex(asset.assetName);
    
                                                        let partnerPairHex;
                                                        let partnerPairString;
                                                        let partnerPairOriginal;
    
                                                        const matchedPair = filteredAssetsPairs.find(pair => pair.mainAssetName === assetNameToHex);
    
                                                        if (matchedPair) {
                                                            const { mainPolicyId, mainAssetName, partnerPolicyId, partnerAssetName } = matchedPair;
    
                                                            partnerPairHex = partnerAssetName;
                                                        }
    
                                                        if (partnerPairHex != null) {
                                                            function hexToString(hex) {
                                                                let string = '';
                                                                for (let i = 0; i < hex.length; i += 2) {
                                                                    string += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
                                                                }
                                                                return string;
                                                            }
    
                                                            // Define a mapping of parameters and their replacement values
                                                            const replacements = {
                                                                "KWIC": "#",
                                                                "Playable": "",
                                                                "�@": "",
                                                                "Avatar": "",
                                                                "-": "",
                                                                "#": "",
                                                                " ": "",
                                                                "á@": "",
                                                            };
    
                                                            // Your original string
                                                            const originalString = hexToString(partnerPairHex);
    
                                                            // Create a regex pattern that matches any of the parameters to be replaced
                                                            const regexPattern = new RegExp(Object.keys(replacements).join("|"), "g");
    
                                                            // Replace the matched parameters with their corresponding values
                                                            partnerPairString = originalString.replace(regexPattern, (match) => replacements[match]);
    
                                                            function hexToString2(hex) {
                                                                let string = '';
                                                                for (let i = 0; i < hex.length; i += 2) {
                                                                    string += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
                                                                }
                                                                return string;
                                                            }
    
                                                            // Define a mapping of parameters and their replacement values
                                                            const replacements2 = {
                                                                "á@": "�@",
                                                            };
    
                                                            // Your original string
                                                            const originalString2 = hexToString2(partnerPairHex);
    
                                                            // Create a regex pattern that matches any of the parameters to be replaced
                                                            const regexPattern2 = new RegExp(Object.keys(replacements2).join("|"), "g");
    
                                                            // Replace the matched parameters with their corresponding values
                                                            partnerPairOriginal = originalString2.replace(regexPattern2, (match) => replacements2[match]);
                                                        }
    
                                                        let pairFingerprint;
    
                                                        const matchedFingerprint = allowedAssets.find(pair => pair.assetName === partnerPairOriginal);
                                                        if (matchedFingerprint) {
                                                            const { fingerprint } = matchedFingerprint;
    
                                                            pairFingerprint = fingerprint;
                                                        }
    
    
                                                        let playableImageUrl;
                                                        if (pairFingerprint != null) {
                                                            const domain = nftDomain;
                                                            const key = CryptoJS.enc.Base64.parse(apiKey);
                                                            const token = pairFingerprint;
    
                                                            function buildUrl(domain, token, uri, params) {
                                                                const searchParams = new URLSearchParams(params);
                                                                return `https://${token}.${domain}.nftcdn.io${uri}?${searchParams.toString()}`;
                                                            }
    
                                                            function nftcdnUrl(domain, key, token, uri, params = {}) {
                                                                params.tk = "";
                                                                let url = buildUrl(domain, token, uri, params);
                                                                params.tk = CryptoJS.enc.Base64url.stringify(CryptoJS.HmacSHA256(url, key));
                                                                return buildUrl(domain, token, uri, params);
                                                            }
    
                                                            playableImageUrl = nftcdnUrl(domain, key, token, "/image", { size: 512 });
                                                        }
    
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <div className="project-box-style7">
                                                                    {Array.isArray(potentialRewards) ? (
                                                                        potentialRewards.map((item, index) => (
                                                                            item.assetName === assetNameToHex && (
                                                                                item.daysStaked >= daysReqToStabilise ? (
                                                                                    <>
                                                                                        {selectedNFTs.length === maxSelectedNFTs && !selectedNFTs.includes(asset.assetName) ? (
                                                                                            <>
                                                                                                <div className="nft_disabled"></div>
                                                                                                <div key={index} className="nft_selection_full" >
                                                                                                    <div className="image" >
                                                                                                        <p className="days_staked">DAYS STABILISING: {item.daysStaked} DAYS</p>
                                                                                                        <img src={originalImageUrl} alt="" />
                                                                                                        {partnerPairHex != null ? (
                                                                                                            <div className="bg_style">
                                                                                                                <div className="image_inner">
                                                                                                                    
                                                                                                                    <p>PAIRED WITH KWIC PLAYABLE {partnerPairString}</p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        ) : (
                                                                                                            <></>
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div className="content">
                                                                                                        <h5 className="heading">{replacedString}</h5>
                                                                                                        <ul>
    
                                                                                                            <li>
                                                                                                                <p>{dailyRewards.find(item => item.assetName === assetNameToHex).rewards} $KWIC/DAY</p>
                                                                                                            </li>
    
                                                                                                            <li>
                                                                                                                <p>{collectionResult}</p>
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                        <div className="content-progress-box style2">
                                                                                                            <div className="progress-bar" data-percentage="100%">
                                                                                                                <div className="progress-title-holder">
                                                                                                                    <span className="progress-title">
                                                                                                                        <span className="progress_number">
                                                                                                                            <span className="progress-number-mark">
                                                                                                                                <span><img src={kwic_token_icon} className="kwic_token_icon" alt="" /> <span className="num">{item.rewards % 1 === 0 ? item.rewards.toFixed(0) : item.rewards.toFixed(1)}</span></span>
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        ) : (
                                                                                            partnerPairHex != null ? (
                                                                                                <>
                                                                                                    {item.spend_utxo_status === 'AWAITING_SIGNATURE' ? (
                                                                                                        <>
                                                                                                            <div className="nft_disabled"></div>
                                                                                                            <h3 className="nft_stabilizing">AWAITING SIGNATURE</h3>
                                                                                                            <div className={`nft_selection`} >
                                                                                                                <div className="image" >
                                                                                                                    <p className="days_staked">DAYS STABILISING: {item.daysStaked} DAYS</p>
                                                                                                                    <img src={originalImageUrl} alt="" />
                                                                                                                    {partnerPairHex != null ? (
                                                                                                                        <div className="bg_style">
                                                                                                                            <div className="image_inner">
                                                                                                                               
                                                                                                                                <p>PAIRED WITH KWIC PLAYABLE {partnerPairString}</p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    ) : (
                                                                                                                        <></>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                                <div className="content">
                                                                                                                    <h5 className="heading">{replacedString}</h5>
                                                                                                                    <ul>
                                                                                                                        <li>
                                                                                                                            <p>{dailyRewards.find(item => item.assetName === assetNameToHex).rewards} $KWIC/DAY</p>
                                                                                                                        </li>
                                                                                                                        <li>
                                                                                                                            <p>{collectionResult}</p>
                                                                                                                        </li>
                                                                                                                    </ul>
                                                                                                                    <div className="content-progress-box style2">
                                                                                                                        <div className="progress-bar" data-percentage="100%">
                                                                                                                            <div className="progress-title-holder">
                                                                                                                                <span className="progress-title">
                                                                                                                                    <span className="progress_number">
                                                                                                                                        <span className="progress-number-mark">
                                                                                                                                            <span><img src={kwic_token_icon} className="kwic_token_icon" alt="" /> <span className="num">{item.rewards % 1 === 0 ? item.rewards.toFixed(0) : item.rewards.toFixed(1)}</span></span>
                                                                                                                                        </span>
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            {item.spend_utxo_status === 'PENDING' ? (
                                                                                                                <>
                                                                                                                    <div className="nft_disabled"></div>
                                                                                                                    <h3 className="nft_stabilizing">PENDING TRANSACTION</h3>
                                                                                                                    <div className={`nft_selection`} >
                                                                                                                        <div className="image" >
                                                                                                                            <p className="days_staked">DAYS STABILISING: {item.daysStaked} DAYS</p>
                                                                                                                            <img src={originalImageUrl} alt="" />
                                                                                                                            {partnerPairHex != null ? (
                                                                                                                                <div className="bg_style">
                                                                                                                                    <div className="image_inner">
                                                                                                                                        
                                                                                                                                        <p>PAIRED WITH KWIC PLAYABLE {partnerPairString}</p>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            ) : (
                                                                                                                                <></>
                                                                                                                            )}
                                                                                                                        </div>
                                                                                                                        <div className="content">
                                                                                                                            <h5 className="heading">{replacedString}</h5>
                                                                                                                            <ul>
                                                                                                                                <li>
                                                                                                                                    <p>{dailyRewards.find(item => item.assetName === assetNameToHex).rewards} $KWIC/DAY</p>
                                                                                                                                </li>
                                                                                                                                <li>
                                                                                                                                    <p>{collectionResult}</p>
                                                                                                                                </li>
                                                                                                                            </ul>
                                                                                                                            <div className="content-progress-box style2">
                                                                                                                                <div className="progress-bar" data-percentage="100%">
                                                                                                                                    <div className="progress-title-holder">
                                                                                                                                        <span className="progress-title">
                                                                                                                                            <span className="progress_number">
                                                                                                                                                <span className="progress-number-mark">
                                                                                                                                                    <span><img src={kwic_token_icon} className="kwic_token_icon" alt="" /> <span className="num">{item.rewards % 1 === 0 ? item.rewards.toFixed(0) : item.rewards.toFixed(1)}</span></span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </>
                                                                                                            ) : (
                                                                                                                <Link key={index} alt={asset.assetName} className={`nft_selection ${selectedNFTs.includes(asset.assetName) ? 'selected' : ''}`} onClick={() => {
                                                                                                                    handleNFTClick(asset.assetName, asset.policyId)
                                                                                                                }} >
                                                                                                                    <div className="image" >
                                                                                                                        <p className="days_staked">DAYS STABILISING: {item.daysStaked} DAYS</p>
                                                                                                                        <img src={originalImageUrl} alt="" />
                                                                                                                        {partnerPairHex != null ? (
                                                                                                                            <div className="bg_style">
                                                                                                                                <div className="image_inner">
                                                                                                                                   
                                                                                                                                    <p>PAIRED WITH KWIC PLAYABLE {partnerPairString}</p>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        ) : (
                                                                                                                            <></>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                    <div className="content">
                                                                                                                        <h5 className="heading">{replacedString}</h5>
                                                                                                                        <ul>
                                                                                                                            <li>
                                                                                                                                <p>{dailyRewards.find(item => item.assetName === assetNameToHex).rewards} $KWIC/DAY</p>
                                                                                                                            </li>
                                                                                                                            <li>
                                                                                                                                <p>{collectionResult}</p>
                                                                                                                            </li>
                                                                                                                        </ul>
                                                                                                                        <div className="content-progress-box style2">
                                                                                                                            <div className="progress-bar" data-percentage="100%">
                                                                                                                                <div className="progress-title-holder">
                                                                                                                                    <span className="progress-title">
                                                                                                                                        <span className="progress_number">
                                                                                                                                            <span className="progress-number-mark">
                                                                                                                                                <span><img src={kwic_token_icon} className="kwic_token_icon" alt="" /> <span className="num">{item.rewards % 1 === 0 ? item.rewards.toFixed(0) : item.rewards.toFixed(1)}</span></span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </Link>
                                                                                                            )}
                                                                                                        </>
                                                                                                    )}
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {item.spend_utxo_status === 'AWAITING_SIGNATURE' ? (
                                                                                                        <>
                                                                                                            <div className="nft_disabled"></div>
                                                                                                            <h3 className="nft_stabilizing">AWAITING SIGNATURE</h3>
                                                                                                            <div className={`nft_selection`} >
                                                                                                                <div className="image" >
                                                                                                                    <p className="days_staked">DAYS STABILISING: {item.daysStaked} DAYS</p>
                                                                                                                    <img src={originalImageUrl} alt="" />
                                                                                                                    {partnerPairHex != null ? (
                                                                                                                        <div className="bg_style">
                                                                                                                            <div className="image_inner">
                                                                                                                                
                                                                                                                                <p>PAIRED WITH KWIC PLAYABLE {partnerPairString}</p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    ) : (
                                                                                                                        <></>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                                <div className="content">
                                                                                                                    <h5 className="heading">{replacedString}</h5>
                                                                                                                    <ul>
                                                                                                                        <li>
                                                                                                                            <p>{dailyRewards.find(item => item.assetName === assetNameToHex).rewards} $KWIC/DAY</p>
                                                                                                                        </li>
                                                                                                                        <li>
                                                                                                                            <p>{collectionResult}</p>
                                                                                                                        </li>
                                                                                                                    </ul>
                                                                                                                    <div className="content-progress-box style2">
                                                                                                                        <div className="progress-bar" data-percentage="100%">
                                                                                                                            <div className="progress-title-holder">
                                                                                                                                <span className="progress-title">
                                                                                                                                    <span className="progress_number">
                                                                                                                                        <span className="progress-number-mark">
                                                                                                                                            <span><img src={kwic_token_icon} className="kwic_token_icon" alt="" /> <span className="num">{item.rewards % 1 === 0 ? item.rewards.toFixed(0) : item.rewards.toFixed(1)}</span></span>
                                                                                                                                        </span>
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            {item.spend_utxo_status === 'PENDING' ? (
                                                                                                                <>
                                                                                                                    <div className="nft_disabled"></div>
                                                                                                                    <h3 className="nft_stabilizing">PENDING TRANSACTION</h3>
                                                                                                                    <div className={`nft_selection`} >
                                                                                                                        <div className="image" >
                                                                                                                            <p className="days_staked">DAYS STABILISING: {item.daysStaked} DAYS</p>
                                                                                                                            <img src={originalImageUrl} alt="" />
                                                                                                                            {partnerPairHex != null ? (
                                                                                                                                <div className="bg_style">
                                                                                                                                    <div className="image_inner">
                                                                                                                                       
                                                                                                                                        <p>PAIRED WITH KWIC PLAYABLE {partnerPairString}</p>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            ) : (
                                                                                                                                <></>
                                                                                                                            )}
                                                                                                                        </div>
                                                                                                                        <div className="content">
                                                                                                                            <h5 className="heading">{replacedString}</h5>
                                                                                                                            <ul>
                                                                                                                                <li>
                                                                                                                                    <p>{dailyRewards.find(item => item.assetName === assetNameToHex).rewards} $KWIC/DAY</p>
                                                                                                                                </li>
                                                                                                                                <li>
                                                                                                                                    <p>{collectionResult}</p>
                                                                                                                                </li>
                                                                                                                            </ul>
                                                                                                                            <div className="content-progress-box style2">
                                                                                                                                <div className="progress-bar" data-percentage="100%">
                                                                                                                                    <div className="progress-title-holder">
                                                                                                                                        <span className="progress-title">
                                                                                                                                            <span className="progress_number">
                                                                                                                                                <span className="progress-number-mark">
                                                                                                                                                    <span><img src={kwic_token_icon} className="kwic_token_icon" alt="" /> <span className="num">{item.rewards % 1 === 0 ? item.rewards.toFixed(0) : item.rewards.toFixed(1)}</span></span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </>
                                                                                                            ) : (
                                                                                                                <Link key={index} alt={asset.assetName} className={`nft_selection ${selectedNFTs.includes(asset.assetName) ? 'selected' : ''}`} onClick={() => {
                                                                                                                    handleNFTClick(asset.assetName, asset.policyId);
                                                                                                                }} >
                                                                                                                    <div className="image" >
                                                                                                                        <p className="days_staked">DAYS STABILISING: {item.daysStaked} DAYS</p>
                                                                                                                        <img src={originalImageUrl} alt="" />
                                                                                                                        {partnerPairHex != null ? (
                                                                                                                            <div className="bg_style">
                                                                                                                                <div className="image_inner">
                                                                                                                                    <p>PAIRED WITH KWIC PLAYABLE {partnerPairString}</p>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        ) : (
                                                                                                                            <></>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                    <div className="content">
                                                                                                                        <h5 className="heading">{replacedString}</h5>
                                                                                                                        <ul>
                                                                                                                            <li>
                                                                                                                                <p>{dailyRewards.find(item => item.assetName === assetNameToHex).rewards} $KWIC/DAY</p>
                                                                                                                            </li>
                                                                                                                            <li>
                                                                                                                                <p>{collectionResult}</p>
                                                                                                                            </li>
                                                                                                                        </ul>
                                                                                                                        <div className="content-progress-box style2">
                                                                                                                            <div className="progress-bar" data-percentage="100%">
                                                                                                                                <div className="progress-title-holder">
                                                                                                                                    <span className="progress-title">
                                                                                                                                        <span className="progress_number">
                                                                                                                                            <span className="progress-number-mark">
                                                                                                                                                <span><img src={kwic_token_icon} className="kwic_token_icon" alt="" /> <span className="num">{item.rewards % 1 === 0 ? item.rewards.toFixed(0) : item.rewards.toFixed(1)}</span></span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </Link>
                                                                                                            )}
                                                                                                        </>
                                                                                                    )}
                                                                                                </>
                                                                                            )
                                                                                        )}
                                                                                    </>
                                                                                ) : (
                                                                                    <div key={index} alt={asset.assetName}>
                                                                                        <div className="nft_disabled"></div>
                                                                                        <h3 className="nft_stabilizing">STABILISING</h3>
                                                                                        <p className="stabilizing_days">IN {daysReqToStabilise - item.daysStaked} {daysReqToStabilise - item.daysStaked === 1 ? ('DAY') : ('DAYS')}</p>
                                                                                        <div className={`nft_selection`} >
                                                                                            <div className="image" >
                                                                                                <img src={originalImageUrl} alt="" />
                                                                                                {partnerPairHex != null ? (
                                                                                                    <div className="bg_style">
                                                                                                        <div className="image_inner">
                                                                                                            
                                                                                                            <p>PAIRED WITH KWIC PLAYABLE {partnerPairString}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <></>
                                                                                                )}
                                                                                            </div>
                                                                                            <div className="content">
                                                                                                <h5 className="heading">{replacedString}</h5>
                                                                                                <ul>
                                                                                                    <li>
                                                                                                        <p>{dailyRewards.find(item => item.assetName === assetNameToHex).rewards} $KWIC/DAY</p>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <p>{collectionResult}</p>
                                                                                                    </li>
                                                                                                </ul>
                                                                                                <div className="content-progress-box style2">
                                                                                                    <div className="progress-bar" data-percentage="100%">
                                                                                                        <div className="progress-title-holder">
                                                                                                            <span className="progress-title">
                                                                                                                <span className="progress_number">
                                                                                                                    <span className="progress-number-mark">
                                                                                                                        <span><img src={kwic_token_icon} className="kwic_token_icon" alt="" /> <span className="num">{item.rewards % 1 === 0 ? item.rewards.toFixed(0) : item.rewards.toFixed(1)}</span></span>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            )
                                                                        ))
                                                                    ) : null}
    
                                                                </div>
                                                            </React.Fragment>
                                                        );
                                                    })
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="container mb-100">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="project_wrapper_2">
                                        <div className="no-wallet-connected"> <h2>CONNECT TO A WALLET</h2></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    }
                </section >
            </div >
        );
    }
}


export default Stabilise;